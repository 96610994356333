import './App.css';
import { BrowserRouter as Router, Switch, Route, useLocation} from 'react-router-dom';
import Home from './pages/Home';
import Contato from './pages/Contato';
import Dashboard from './pages/Dashboard';
import Navbar from './components/Navbar';
import Container from './layout/Container';
import Footer from './layout/Footer';
import Contact from './components/contact';
import Register from './pages/Register';
import AppDash from './pages/AppDash';
import SimulatePayment from './pages/SimulatePayment';
import PagarBoletos from './pages/PagarBoletos'
import PaymentPIX from './pages/PaymentPIX';
import ListTransations from './pages/ListTransations';
import GestaoConta from './pages/GestaoConta';


function AppContent() {
  const location = useLocation();

  // Lista de rotas que não exibem Navbar e Footer
  const hiddenNavbarAndFooterRoutes = [
    '/login',
    '/register',
    '/app',
    '/simulatepayment',
    '/pagar-boletos',
    '/pagar-pix',
    '/list-transations',
    '/account',
  ];

  // Verifica se o caminho atual está na lista
  const showNavbarAndContact = !hiddenNavbarAndFooterRoutes.includes(location.pathname);

  return (
    <div className={`App ${location.pathname === '/login' ? 'dashboardBackground' : ''}`}>
      {showNavbarAndContact && <Contact />}
      {showNavbarAndContact && <Navbar />}
      <Container customClass="min-height">
        <Switch>
          <Route exact path="/">
            <Home />
          </Route>
          <Route path="/login">
            <Dashboard />
          </Route>
          <Route path="/register">
            <Register />
          </Route>
          <Route path="/app">
            <AppDash />
          </Route>
          <Route path="/contato">
            <Contato />
          </Route>
          <Route path="/simulatepayment">
            <SimulatePayment />
          </Route>
          <Route path="/pagar-boletos">
            <PagarBoletos />
          </Route>
          <Route path="/pagar-pix">
            <PaymentPIX />
          </Route>
          <Route path="/list-transations">
            <ListTransations />
          </Route>
          <Route path="/account">
            <GestaoConta />
          </Route>
        </Switch>
      </Container>
      {showNavbarAndContact && <Footer />}
    </div>
  );
}



function App() {
  return (
    <Router>
      <AppContent />
    </Router>
  );
}

export default App;
