import React, { useCallback, useState, useEffect } from 'react';
import Cards from 'react-credit-cards';
import Modal from 'react-modal';
import 'react-credit-cards/es/styles-compiled.css';
import styles from './CheckoutFinish.module.css';
import styles2 from './ModalSimulateComponent.module.css'; // Supondo que você tenha um arquivo CSS


const CheckoutFinish = ({ code }) => {
    // eslint-disable-next-line
    const [data, setData] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const [installmentOptions, setInstallmentOptions] = useState([]);
    // eslint-disable-next-line
    const [isLoading, setIsLoading] = useState(false);
    const [isButtonClicked, setIsButtonClicked] = useState(false);
    const [firstCardValuee, setFirstCardValuee] = useState(0);
    const [secondCardValuee, setSecondCardValuee] = useState(0);
    const [remainingTotal, setRemainingTotal] = useState(0); // Valor restante
    const [secondCardNumericValue, setSecondCardNumericValue] = useState(0); // Valor numérico do segundo cartão
    const [firstCardInstallmentSummary, setFirstCardInstallmentSummary] = useState('');
    const [secondCardInstallmentSummary, setSecondCardInstallmentSummary] = useState('');
    // eslint-disable-next-line
    const [paymentOption, setPaymentOption] = useState('oneCard');
    const [options, setOptions] = useState([]);
    // eslint-disable-next-line
    const [paymentMethod, setPaymentMethod] = useState('credito');
    const [optionsCalculated, setOptionsCalculated] = useState(false); // Estado para controlar se as opções já foram calculadas
    // eslint-disable-next-line
    const [EmailClient, setEmailClient] = useState('');
    const storedCpfCnpj = localStorage.getItem('cpfcnpj');
    const [mobilePhone, setMobilePhone] = useState('');
    // eslint-disable-next-line
    const [qrCode, setQrCode] = useState('');
    // eslint-disable-next-line
    const [paymentId, setPaymentId] = useState('');
    const [pixCode, setPixCode] = useState('');
    // eslint-disable-next-line
    const [isPaymentReceived, setIsPaymentReceived] = useState(false); // Estado para controle de recebimento
    // eslint-disable-next-line
    const [initialTotal, setInitialTotal] = useState(0); // Estado para armazenar o valor total calculado
    const [initialTotal1x, setInitialTotal1x] = useState(0); // Estado para armazenar o valor total calculado
    const [initialTotalPIX, setInitialTotalPIX] = useState(0); // Estado para armazenar o valor total calculado
    // eslint-disable-next-line
    const [installmentValue, setInstallmentValue] = useState(0);
    const [ReceiptUrl2, setReceiptUrl] = useState('');
    // eslint-disable-next-line
    const [totalAmount, setTotalAmount] = useState(0); // Exemplo de valor total
    const [focused, setFocused] = useState('number');
    // Estado para armazenar a parcela selecionada
    const [installments, setInstallments] = useState('12'); // Inicializa com 12x como a melhor opção
    // eslint-disable-next-line
    const [openModal2, setModalIsOpen] = useState(false);
    const [openModal3, setModalIsOpen3] = useState(false);
    Modal.setAppElement('#root'); // Substitua '#root' pelo elemento principal da sua aplicação
    const handleRedirect = () => {
        if (ReceiptUrl2) {
            window.open(ReceiptUrl2, '_blank'); // Abre a URL em uma nova aba
        }
    };
    const handleDashboard = () => {
        window.location.href = '/app'; // Redireciona para a URL 
    };
    const openModal = () => {
        setModalIsOpen(true);
    };
    const closeModal = () => {
        setModalIsOpen(false);
    };
    const openModal33 = () => {
        setModalIsOpen3(true);
    };
    const closeModal33 = () => {
        setModalIsOpen3(false);
    };
    const [cardDetails, setCardDetails] = useState({
        cvc: '',
        expiry: '',
        name: '',
        number: '',
    });
    const [cardDetails2, setCardDetails2] = useState({
        cvc: '',
        expiry: '',
        name: '',
        number: '',
    });
    // Estado para os dados do endereço de fatura
    // eslint-disable-next-line
    const [billingAddress, setBillingAddress] = useState({
        street: '',
        number: '',
        complement: '',
        neighborhood: '',
        city: '',
        state: '',
        zipCode: '',
    });
    const fetchUserData = async (cpfcnpj) => {
        try {
            const response = await fetch(`https://carinapaga.com.br/api/user/${cpfcnpj}`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                throw new Error('Erro ao buscar os dados do usuário');
            }

            const data = await response.json();
            const Email = data.email;
            const Phone = data.phone;
            setMobilePhone(Phone);
            setEmailClient(Email);

        } catch (error) {
            console.error('Erro ao buscar os dados do usuário:', error);
        }
    };

    // Chama a função passando o CPF/CNPJ armazenado
    if (storedCpfCnpj) {
        fetchUserData(storedCpfCnpj);
    }

    // Atualiza os dados do endereço
    const handleAddressInputChange = (e) => {
        const { name, value } = e.target;
        setBillingAddress((prevState) => ({ ...prevState, [name]: value }));
    };


    const handleInstallmentChange = (e) => {
        const selectedInstallment = parseInt(e.target.value, 10); // Converte para número
        setInstallments(selectedInstallment); // Atualiza o estado de parcelas
        console.log("Selected Installment:", selectedInstallment);

        // Ajusta o valor inicial com base no número de parcelas
        const value = selectedInstallment === 1 ? initialTotal1x : initialTotal;

        // Verifica se o número de parcelas foi alterado
        if (selectedInstallment !== installments) {
            // Recalcula os valores com base nas novas parcelas
            console.log("Valor inicial:", value);
            console.log("Número de parcelas:", selectedInstallment);
            calculateTotal(value, paymentMethod, selectedInstallment); // Chama a função para recalcular o total e o valor das parcelas
        }
    };

    // useEffect(() => {
    //     if (paymentOption === 'pix') {
    //         setPaymentMethod('pix');
    //         setTotalAmount(initialTotalPIX); // Define o totalAmount como o valor do PIX
    //     }
    // }, [paymentOption]);

    const isSinglePayment = installments === 1; // Verifica se a parcela é 1x
    const handleCardValueChange = (e, card) => {
        const rawValue = e.target.value.replace(/\D/g, ''); // Remove caracteres não numéricos
        const numericValue = parseFloat(rawValue) / 100; // Converte para número
        const formattedValue = numericValue.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
        }); // Formata como moeda brasileira
    
        const totalValue = initialTotal; // Valor total inicial
    
        if (card === 'first') {
            if (numericValue <= totalValue) {
                setFirstCardValuee(formattedValue); // Atualiza o valor formatado do primeiro cartão
                const newRemainingTotal = totalValue - numericValue; // Calcula o valor restante
    
                setRemainingTotal(newRemainingTotal); // Atualiza o valor restante imediatamente
    
                const formattedRemaining = newRemainingTotal.toLocaleString('pt-BR', {
                    style: 'currency',
                    currency: 'BRL',
                });
                setSecondCardValuee(formattedRemaining); // Atualiza automaticamente o segundo cartão
    
                // Verifica se a parcela é 1x
                if (isSinglePayment) {
                    setFirstCardInstallmentSummary(`1x de ${formattedValue}`);
                    setSecondCardInstallmentSummary(`1x de ${formattedRemaining}`);
                } else {
                    // Calcula as parcelas
                    const firstCardInstallmentValue = numericValue / installments;
                    const secondCardInstallmentValue = newRemainingTotal / installments;
    
                    setFirstCardInstallmentSummary(
                        `${installments}x de ${firstCardInstallmentValue.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                        })}`
                    );
                    setSecondCardInstallmentSummary(
                        `${installments}x de ${secondCardInstallmentValue.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                        })}`
                    );
                }
            } else {
                alert('Valor do primeiro cartão não pode ser maior que o valor total.');
            }
        } else if (card === 'second') {
            const remainingLimit = totalValue - (parseFloat(firstCardValuee.replace(/\D/g, '')) / 100);
    
            if (numericValue <= remainingLimit) {
                setSecondCardValuee(formattedValue); // Atualiza o valor formatado do segundo cartão
                setRemainingTotal(remainingLimit - numericValue); // Atualiza o valor restante após a alteração do segundo cartão
    
                // Verifica se a parcela é 1x
                if (isSinglePayment) {
                    setSecondCardInstallmentSummary(`1x de ${formattedValue}`);
                } else {
                    // Calcula as parcelas do segundo cartão
                    const secondCardInstallmentValue = numericValue / installments;
                    setSecondCardInstallmentSummary(
                        `${installments}x de ${secondCardInstallmentValue.toLocaleString('pt-BR', {
                            style: 'currency',
                            currency: 'BRL',
                        })}`
                    );
                }
            } else {
                alert(`Valor do segundo cartão não pode ser maior que R$ ${remainingLimit.toFixed(2)}.`);
            }
        }
    };

useEffect(() => {
    const formattedRemaining = remainingTotal.toLocaleString('pt-BR', {
        style: 'currency',
        currency: 'BRL',
    });

    // Atualiza o valor do segundo cartão automaticamente
    if (remainingTotal > 0) {
        setSecondCardValuee(formattedRemaining);
    }
}, [remainingTotal]); // Dependência em remainingTotal


    const fetchData = useCallback(async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await fetch('https://carinapaga.com.br/api/app/consultation', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({ billType: code }),
            });

            const result = await response.json();

            if (response.ok) {
                setData(result.data.simulationResult.bankSlipInfo);
                const billValue = result.data.simulationResult.bankSlipInfo.value;
                setData(billValue);
                calculateTotal(billValue, paymentMethod, installments); // Atualiza o total
            } else {
                const errorMessage = result.errors?.[0]?.description || result.message || 'Erro desconhecido';
                setError(errorMessage);
            }
        } catch (error) {
            setError('Erro ao conectar com o servidor.');
        } finally {
            setLoading(false);
        }
        // eslint-disable-next-line
    }, [code]);

    useEffect(() => {
        if (code) {
            fetchData();
        }
    }, [code, fetchData]);

    const handleCheckboxChange = (e) => {
        setIsChecked(e.target.checked);
    };

    const handleInputChange = (e, cardNumber) => {
        const { name, value } = e.target;
        if (cardNumber === 1) {
            setCardDetails((prevState) => ({ ...prevState, [name]: value }));
        } else {
            setCardDetails2((prevState) => ({ ...prevState, [name]: value }));
        }
    };

    const handleFocusChange = (e) => {
        setFocused(e.target.name);
    };

    const formatCurrency = (value) => {
        return value.toLocaleString('pt-BR', {
            style: 'currency',
            currency: 'BRL',
            minimumFractionDigits: 2,
        });
    };

    const calculateTotal = (value, paymentMethod, installments) => {
        const numericValue = isNaN(parseFloat(value)) ? 0 : parseFloat(value);
        let total = 0;
        let totalpix = 0;

        const calculateCompoundInterest = (principal, rate, periods) => {
            return principal * Math.pow(1 + rate / 100, periods);
        };

        if (paymentMethod === 'credito') {
            let rate = 0;
            if (installments >= 7 && installments <= 12) {
                rate = 7.99;
            } else if (installments >= 2 && installments <= 6) {
                rate = 6.49;
            }

            if (rate > 0) {
                total = calculateCompoundInterest(numericValue + 14.49, rate, installments);
            } else {
                total = numericValue;
            }
        } else if (paymentMethod === 'pix') {
            const percentageFee = numericValue * (0.99 / 100);
            totalpix = numericValue + percentageFee + 5.00;
        } else {
            console.error(`Método de pagamento não reconhecido: ${paymentMethod}`);
            return;
        }

        if (!optionsCalculated) {
            const installmentOptions = Array.from({ length: 12 }, (_, i) => {
                const numInstallments = 12 - i;
                const rate = numInstallments >= 7 ? 7.99 : (numInstallments >= 2 ? 6.49 : 5.99);
                const compoundedValue = calculateCompoundInterest(numericValue, rate, numInstallments) + 14.49;

                const baseInstallmentValue = compoundedValue / numInstallments;
                const roundedBaseInstallmentValue = parseFloat(baseInstallmentValue.toFixed(2));

                const totalRounded = roundedBaseInstallmentValue * numInstallments;
                const adjustment = parseFloat((compoundedValue - totalRounded).toFixed(2));

                const adjustedInstallments = Array.from({ length: numInstallments }, (_, j) => {
                    if (j === numInstallments - 1) {
                        return roundedBaseInstallmentValue + adjustment;
                    }
                    return roundedBaseInstallmentValue;
                });

                return {
                    value: `${numInstallments}`,
                    text: `${numInstallments}x de ${formatCurrency(adjustedInstallments[0])}`, // Formata as parcelas
                    best: numInstallments === 12,
                    installmentValues: adjustedInstallments,
                };
            });

            setOptions(installmentOptions);
            setInstallmentOptions(installmentOptions);
            setOptionsCalculated(true);
        }

        const selectedInstallment = installmentOptions.find(option => option.value === `${installments}`);
        if (selectedInstallment) {
            // Armazena os valores brutos no estado
            const rawInstallmentValue = selectedInstallment.installmentValues[0];
            const rawTotalAmount = selectedInstallment.installmentValues.reduce((acc, val) => acc + val, 0);
        
            setInstallmentValue(rawInstallmentValue); // Valor bruto da parcela
            setTotalAmount(rawTotalAmount); // Total bruto
            setInitialTotal(rawTotalAmount); // Total bruto
            setInitialTotal1x(rawTotalAmount); // Total bruto
        
            // Debug para verificar os valores
            console.log({ rawInstallmentValue, rawTotalAmount, installments });
        }
        

        setInitialTotalPIX(formatCurrency(totalpix));
    };


    // eslint-disable-next-line
    useEffect(() => {
        calculateTotal(data, paymentMethod);
        // eslint-disable-next-line
    }, [paymentMethod, data]);

    const checkPayments = async (paymentIds) => {
        setIsLoading(true);
        const checkInterval = setInterval(async () => {
            const statuses = await Promise.all(paymentIds.map(paymentId => checkPaymentStatus(paymentId)));
            if (statuses.every(status => status === true)) {
                clearInterval(checkInterval);
                setIsLoading(false);
            }
        }, 5000);
    };

    const checkPaymentStatus = async (paymentId) => {
        let timeoutReached = false;

        // Definir o tempo limite de 2 minutos (120.000 ms)
        const timeout = setTimeout(() => {
            timeoutReached = true;
            openModal33(true); // Exibe o modal após 2 minutos sem confirmação
        }, 120000); // 2 minutos

        try {
            const statusResponse = await fetch(
                `https://carinapaga.com.br/api/payments/status/${paymentId}`
            );
            const statusResult = await statusResponse.json();

            console.log('Status do pagamento:', statusResult);
            const ResultPayment = statusResult.paymentId;
            const ReceiptUrl = statusResult.ReceptionURL;
            setReceiptUrl(ReceiptUrl);

            if (statusResult.status === 'RECEIVED') {
                console.log('Pagamento RECEBIDO!');
                openModal(true); // Abre o modal se o pagamento for recebido
                clearTimeout(timeout); // Limpa o timeout, já que o status foi confirmado

                const PaymentBoleto = {
                    billType: code, // billType previamente salvo
                    paymentId: ResultPayment // paymentId recebido
                }

                const PaymentConfirm = await fetch('https://carinapaga.com.br/api/payments/boleto', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(PaymentBoleto),
                });

                const StatusPayment = await PaymentConfirm.json(); // Aguarda a resposta e converte para JSON
                console.log(StatusPayment); // Loga o status do pagamento
                return true;
            } else if (statusResult.status === 'CONFIRMED') {
                console.log('Pagamento CONFIRMADO!');
                openModal(true); // Abre o modal se o pagamento for confirmado
                clearTimeout(timeout); // Limpa o timeout, já que o status foi confirmado

                const PaymentBoleto = {
                    billType: code, // billType previamente salvo
                    paymentId: ResultPayment // paymentId recebido
                }

                const PaymentConfirm = await fetch('https://carinapaga.com.br/api/payments/boleto', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify(PaymentBoleto),
                });

                const StatusPayment = await PaymentConfirm.json(); // Aguarda a resposta e converte para JSON
                console.log(StatusPayment); // Loga o status do pagamento
                return true;
            } else if (statusResult.status === 'RECUSED') {
                console.log('Pagamento RECUSADO!');
                openModal33(true); // Exibe o modal se o pagamento for recusado
                clearTimeout(timeout); // Limpa o timeout, já que o status foi recusado
                return true;
            }

            return false;
        } catch (error) {
            console.error('Erro ao verificar status:', error);
            clearTimeout(timeout); // Limpa o timeout em caso de erro
            return false;
        }
    };

    // Alteração na lógica de envio de pagamento
    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const totalValue = (installments === 1)
            ? parseFloat(initialTotal1x || 0)
            : parseFloat(initialTotal || 0);

            console.log("Hoje tem: " + initialTotal)
            console.log("TotalValue: " + totalValue)

        const installmentCount = installments; // Número de parcelas

        // Valores e parcelas para dois cartões
        const firstCardValue = parseFloat((totalValue / 2).toFixed(2));
        const secondCardValue = parseFloat((totalValue - firstCardValue).toFixed(2));

        const firstCardInstallments =
            (installmentCount === 1)
                ? 1 // Se for 1x, usa o valor total
                : parseInt(firstCardInstallmentSummary?.split('x')[0]) || Math.floor(installmentCount / 2); // Usa as parcelas do cliente ou divide

        const secondCardInstallments =
            (installmentCount === 1)
                ? 1
                : parseInt(secondCardInstallmentSummary?.split('x')[0]) || installmentCount - firstCardInstallments;

        // Dados comuns para cobrança
        const billingData = {
            cpfcnpj: storedCpfCnpj,
            description: 'Pagamento de ' + storedCpfCnpj,
            billingType: 'CREDIT_CARD',
            email: EmailClient,
            postalCode: billingAddress.zipCode,
            addressNumber: billingAddress.number,
            phone: mobilePhone,
            mobilePhone: mobilePhone,
            installmentCount: installmentCount,
        };

        const sendPayment = async (paymentData) => {
            try {
                const response = await fetch('https://carinapaga.com.br/api/app/createCharge/credit', {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(paymentData),
                });
                const result = await response.json();
                console.log('Resposta do servidor:', result);
                return result;
            } catch (error) {
                console.error('Erro ao processar o pagamento:', error);
            }
        };

        let paymentData1;
        let paymentIds = [];

        if (paymentOption === 'oneCard') {
            paymentData1 = {
                ...billingData,
                value: totalValue,
                cardHolderName: cardDetails.name,
                cardNumber: cardDetails.number,
                cardExpiryMonth: cardDetails.expiry.split('/')[0],
                cardExpiryYear: cardDetails.expiry.split('/')[1],
                cardCvv: cardDetails.cvc,
            };

            const result1 = await sendPayment(paymentData1);
            if (result1.status === 'RECEIVED') {
                openModal(true); // Abre o modal se o pagamento for recebido
                setIsLoading(false);
            } else {
                paymentIds.push(result1.paymentId);
                await checkPayments(paymentIds); // Verifica os pagamentos
            }
        } else if (paymentOption === 'twoCards') {
            const paymentData1 = {
                ...billingData,
                value: (installmentCount === 1) ? firstCardValue : parseFloat(firstCardValuee.replace('R$', '').replace(/\./g, '').replace(',', '.').trim()), // Ajuste aqui
                cardHolderName: cardDetails.name,
                cardNumber: cardDetails.number,
                cardExpiryMonth: cardDetails.expiry.split('/')[0],
                cardExpiryYear: cardDetails.expiry.split('/')[1],
                cardCvv: cardDetails.cvc,
                installmentCount: firstCardInstallments,
            };
            
            const paymentData23 = {
                ...billingData,
                value: (installmentCount === 1) ? secondCardValue : parseFloat(secondCardValuee.replace('R$', '').replace(/\./g, '').replace(',', '.').trim()), // Ajuste aqui
                cardHolderName: cardDetails2.name,
                cardNumber: cardDetails2.number,
                cardExpiryMonth: cardDetails2.expiry.split('/')[0],
                cardExpiryYear: cardDetails2.expiry.split('/')[1],
                cardCvv: cardDetails2.cvc,
                installmentCount: secondCardInstallments,
            };
            

            console.log('Enviando pagamento com dois cartões:');
            console.log('Primeiro cartão:', paymentData1);
            console.log('Segundo cartão:', paymentData23);

            const result1 = await sendPayment(paymentData1);
            const result2 = await sendPayment(paymentData23);

            paymentIds.push(result1.paymentId, result2.paymentId);
            // Verifica o status dos dois pagamentos
            const statuses = await Promise.all(paymentIds.map(paymentId => checkPaymentStatus(paymentId)));

            // Se ambos os pagamentos foram recebidos
            if (statuses.every(status => status === true)) {
                openModal(true); // Abre o modal
            } else {
                await checkPayments(paymentIds); // Se algum pagamento não foi confirmado, continua verificando
            }
        }
    };


    const handlePixPaymentSubmit = async () => {
        // Desabilita o botão ao iniciar o processamento
        setIsButtonClicked(true);
        setIsLoading(true);
        const cpfcnpj = localStorage.getItem('cpfcnpj');
        const totalAmount = paymentOption === 'pix' ? initialTotalPIX : initialTotal;

        if (!cpfcnpj) {
            alert('CPF/CNPJ não encontrado. Por favor, faça login novamente.');
            return;
        }

        const paymentData = {
            cpfcnpj: cpfcnpj,
            value: totalAmount,
            description: 'Pagamento via PIX',
            billingType: 'PIX',
        };

        try {
            console.log('Dados enviados:', paymentData);

            const response = await fetch('https://carinapaga.com.br/api/app/createCharge', {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(paymentData),
            });

            const result = await response.json();

            if (response.ok) {
                console.log('Pagamento via PIX processado com sucesso:', result);
                setQrCode(result.qrCode);
                setPixCode(result.pixCode);
                setPaymentId(result.paymentId);
                setIsPaymentReceived(true);

                const checkPaymentStatus = async () => {
                    try {
                        const statusResponse = await fetch(
                            `https://carinapaga.com.br/api/payments/status/${result.paymentId}`
                        );
                        const statusResult = await statusResponse.json();
                        const ResultPayment = statusResult.paymentId;
                        if (statusResponse.ok) {
                            console.log('Status do pagamento:', statusResult.status);
                            if (statusResult.status === 'RECEIVED') {
                                clearInterval(checkInterval); // Para o intervalo
                                openModal(true); // Abre o modal se o pagamento for recebido

                                const PaymentBoleto = {
                                    billType: code, // billType previamente salvo
                                    paymentId: ResultPayment // paymentId recebido
                                }

                                const PaymentConfirm = await fetch('https://carinapaga.com.br/api/payments/boleto', {
                                    method: 'POST',
                                    headers: {
                                        'Content-Type': 'application/json',
                                    },
                                    body: JSON.stringify(PaymentBoleto),
                                });

                                const StatusPayment = await PaymentConfirm.json(); // Aguarda a resposta e converte para JSON
                                console.log(StatusPayment); // Loga o status do pagamento
                            }
                        } else {
                            console.error('Erro ao verificar status:', statusResult.error);
                        }
                    } catch (error) {
                        console.error('Erro na verificação de status:', error);
                    }
                };

                const checkInterval = setInterval(checkPaymentStatus, 5000);
            } else {
                console.error('Erro ao processar pagamento via PIX:', result.error);
                alert('Erro ao processar pagamento. Tente novamente.');
            }
        } catch (error) {
            console.error('Erro na requisição:', error);
            alert('Erro na comunicação com o servidor. Tente novamente.');
        } finally {
            setIsLoading(false); // Finaliza carregamento
        }
    };
    const renderPaymentSummary = () => {
        const totalAmount = paymentOption === 'pix' ? initialTotalPIX : initialTotal;
        const installmentValue = installments === 1 ? initialTotal1x : totalAmount / installments;

        const paymentMethodText = paymentOption === 'pix'
            ? 'Pix'
            : paymentOption === 'oneCard'
                ? '1 Cartão de Crédito'
                : '2 Cartões de Crédito';

        return (
            <div className={styles.transactionSummary}>
                <h3>Resumo da Transação</h3>
                <table className={styles.transactionTable}>
                    <thead>
                        <tr>
                            <th>Descrição</th>
                            <th>Detalhes</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><strong>Forma de Pagamento:</strong></td>
                            <td>{paymentMethodText}</td>
                        </tr>
                        <tr>
                            <td><strong>Valor Total:</strong></td>
                            <td>
                                {paymentOption === 'pix'
                                    ? formatCurrency(initialTotalPIX)
                                    : installments === 1
                                        ? formatCurrency(initialTotal1x)
                                        : formatCurrency(totalAmount)}
                            </td>
                        </tr>

                        {paymentOption === 'pix' && (
                            <tr>
                                <td><strong>Serviço de Conveniência:</strong></td>
                                <td>R$ 5,00</td>
                            </tr>
                        )}
                        {paymentOption === 'oneCard' || paymentOption === 'twoCards' ? (
                            <tr>
                                <td><strong>Serviço de Conveniência:</strong></td>
                                <td>R$ 14,49</td>
                            </tr>
                        ) : null}
                        {paymentOption !== 'pix' && (
                            <tr>
                                <td><strong>Parcelas:</strong></td>
                                <td>
                                    {installments === 1
                                        ? `1x de ${formatCurrency(initialTotal1x)}`
                                        : `${installments}x de ${formatCurrency(installmentValue)}`}
                                </td>
                            </tr>
                        )}

                        {paymentOption === 'twoCards' && (
                            <>
                                <tr>
                                    <td colSpan="2" className={styles.centeredText}>
                                        <strong>Pagamento dividido entre 2 cartões.</strong>
                                    </td>
                                </tr>
                                <tr>
                                    <td><strong>Valor no Primeiro Cartão:</strong></td>
                                    <td>{firstCardValuee || 'R$ 0,00'}</td>
                                </tr>
                                <tr>
                                    <td><strong>Parcelas no Primeiro Cartão:</strong></td>
                                    <td>{firstCardInstallmentSummary || '0x de R$ 0,00'}</td>
                                </tr>
                                <tr>
                                    <td><strong>Valor no Segundo Cartão:</strong></td>
                                    <td>{secondCardValuee || 'R$ 0,00'}</td>
                                </tr>
                                <tr>
                                    <td><strong>Parcelas no Segundo Cartão:</strong></td>
                                    <td>{secondCardInstallmentSummary || '0x de R$ 0,00'}</td>
                                </tr>
                                {paymentOption !== 'pix' && paymentOption !== 'twoCards' && (
                                    <tr>
                                        <td><strong>Parcelas por Cartão:</strong></td>
                                        <td>{installments}x de R$ {(installmentValue / 2).toFixed(2)}</td>
                                    </tr>
                                )}
                            </>
                        )}
                    </tbody>
                </table>
            </div>
        );
    };

    const renderPaymentFields = () => {
        switch (paymentOption) {
            case 'oneCard':
                return (
                    <div className={styles.cardDetails}>
                        <h3>Cartão de Crédito</h3>
                        <Cards
                            cvc={cardDetails.cvc}
                            expiry={cardDetails.expiry}
                            focused={focused}
                            name={cardDetails.name}
                            number={cardDetails.number}
                        />
                        <div className={styles.bestOption}>
                            <span className={styles.highlightText}>Quantidade de Parcelas Disponibilizada:</span>
                            <div className={styles.installmentsOptions}>
                                {options.map((option) => (
                                    <label
                                        key={option.value}
                                        className={`${styles.cardOption} ${Number(installments) === Number(option.value) ? styles.highlight : ''}`} // Garantir que ambos sejam números para comparação
                                    >
                                        <input
                                            type="radio"
                                            name="installments"
                                            value={option.value}
                                            checked={Number(installments) === Number(option.value)} // Garantir que ambos sejam números
                                            onChange={handleInstallmentChange}
                                        />
                                        <div className={styles.card}>
                                            <h4>{`${option.value}x`}</h4>
                                            <p className={styles.cardText}>{option.text}</p>
                                            {option.best && <span className={styles.bestBadge}>Melhor opção!</span>}
                                        </div>
                                    </label>
                                ))}
                            </div>
                        </div>
                        <br></br>
                        <label>Número do Cartão</label>
                        <input
                            type="text"
                            name="number"
                            placeholder="Número do Cartão"
                            className={styles.input}
                            value={cardDetails.number}
                            onChange={(e) => handleInputChange(e, 1)}
                            onFocus={handleFocusChange}
                        />

                        <div>
                            <label>Validade (MM/AA)</label>
                            <input
                                type="text"
                                name="expiry"
                                placeholder="MM/AA"
                                className={styles.input}
                                value={cardDetails.expiry}
                                onChange={(e) => handleInputChange(e, 1)}
                                onFocus={handleFocusChange}
                            />
                        </div>
                        <div>
                            <label>CVV</label>
                            <input
                                type="text"
                                name="cvc"
                                placeholder="CVV"
                                className={styles.input}
                                value={cardDetails.cvc}
                                onChange={(e) => handleInputChange(e, 1)}
                                onFocus={handleFocusChange}
                            />
                        </div>

                        <label>Nome Impresso no Cartão</label>
                        <input
                            type="text"
                            name="name"
                            placeholder="Nome no Cartão"
                            className={styles.input}
                            value={cardDetails.name}
                            onChange={(e) => handleInputChange(e, 1)}
                            onFocus={handleFocusChange}
                        />
                    </div>
                );
            case 'twoCards':
                return (
                    <div className={styles.cardDetails}>
                        <h3>Pagamento com Dois Cartões</h3>

                        {/* Primeira parte do pagamento (Primeiro Cartão) */}
                        <Cards
                            cvc={cardDetails.cvc}
                            expiry={cardDetails.expiry}
                            focused={focused}
                            name={cardDetails.name}
                            number={cardDetails.number}
                        />

                        <div className={styles.bestOption}>
                            <span className={styles.highlightText}>Quantidade de Parcelas Disponibilizada:</span>
                            <div className={styles.installmentsOptions}>
                                {options.map((option) => (
                                    <label
                                        key={option.value}
                                        className={`${styles.cardOption} ${Number(installments) === Number(option.value) ? styles.highlight : ''}`}
                                    >
                                        <input
                                            type="radio"
                                            name="installments"
                                            value={option.value}
                                            checked={Number(installments) === Number(option.value)}
                                            onChange={handleInstallmentChange}
                                        />
                                        <div className={styles.card}>
                                            <h4>{`${option.value}x`}</h4>
                                            <p className={styles.cardText}>{option.text}</p>
                                            {option.best && <span className={styles.bestBadge}>Melhor opção!</span>}
                                        </div>
                                    </label>
                                ))}
                            </div>
                        </div><br />

                        {/* Primeiro Cartão */}
                        <label>Número do Primeiro Cartão</label>
                        <input
                            type="text"
                            name="number"
                            placeholder="Número do Primeiro Cartão"
                            className={styles.input}
                            value={cardDetails.number}
                            onChange={(e) => handleInputChange(e, 1)}
                            onFocus={handleFocusChange}
                        />

                        <label>Validade (MM/AA)</label>
                        <input
                            type="text"
                            name="expiry"
                            placeholder="MM/AA"
                            className={styles.input}
                            value={cardDetails.expiry}
                            onChange={(e) => handleInputChange(e, 1)}
                            onFocus={handleFocusChange}
                        />

                        <label>CVV</label>
                        <input
                            type="text"
                            name="cvc"
                            placeholder="CVV"
                            className={styles.input}
                            value={cardDetails.cvc}
                            onChange={(e) => handleInputChange(e, 1)}
                            onFocus={handleFocusChange}
                        />

                        <label>Nome Impresso no Primeiro Cartão</label>
                        <input
                            type="text"
                            name="name"
                            placeholder="Nome no Primeiro Cartão"
                            className={styles.input}
                            value={cardDetails.name}
                            onChange={(e) => handleInputChange(e, 1)}
                            onFocus={handleFocusChange}
                        />

                        {!isSinglePayment && (
                            <div className={styles.cardValueContainer}>
                                {/* Valor para o primeiro cartão */}
                                <label className={styles.label}>Valor no Primeiro Cartão</label>
                                <input
                                    type="text"
                                    name="firstCardValuee"
                                    placeholder="Digite o valor (ex: R$100,00)"
                                    className={styles.input}
                                    value={firstCardValuee}
                                    onChange={(e) => handleCardValueChange(e, 'first')}
                                />
                                <p className={styles.limitInfo}>
                                    Limite total disponível: <strong>R$ {initialTotal.toFixed(2)}</strong>
                                </p>
                                <p className={styles.remainingInfo}>
                                    Valor disponível restante: <strong>R$ {remainingTotal.toFixed(2)}</strong>
                                </p>
                            </div>
                        )}

                        {/* Segundo Cartão */}
                        <h3>Detalhes do Segundo Cartão</h3>
                        <Cards
                            cvc={cardDetails2.cvc}
                            expiry={cardDetails2.expiry}
                            focused={focused}
                            name={cardDetails2.name}
                            number={cardDetails2.number}
                        />

                        <label>Número do Segundo Cartão</label>
                        <input
                            type="text"
                            name="number"
                            placeholder="Número do Segundo Cartão"
                            className={styles.input}
                            value={cardDetails2.number}
                            onChange={(e) => handleInputChange(e, 2)}
                            onFocus={handleFocusChange}
                        />

                        <label>Validade (MM/AA)</label>
                        <input
                            type="text"
                            name="expiry"
                            placeholder="MM/AA"
                            className={styles.input}
                            value={cardDetails2.expiry}
                            onChange={(e) => handleInputChange(e, 2)}
                            onFocus={handleFocusChange}
                        />

                        <label>CVV</label>
                        <input
                            type="text"
                            name="cvc"
                            placeholder="CVV"
                            className={styles.input}
                            value={cardDetails2.cvc}
                            onChange={(e) => handleInputChange(e, 2)}
                            onFocus={handleFocusChange}
                        />

                        <label>Nome Impresso no Segundo Cartão</label>
                        <input
                            type="text"
                            name="name"
                            placeholder="Nome no Segundo Cartão"
                            className={styles.input}
                            value={cardDetails2.name}
                            onChange={(e) => handleInputChange(e, 2)}
                            onFocus={handleFocusChange}
                        />

                        {!isSinglePayment && (
                            <div className={styles.cardValueContainer}>
                                <label className={styles.label}>Valor no Segundo Cartão</label>
                                <input
                                    type="text"
                                    name="secondCardValuee"
                                    placeholder="Digite o valor (ex: R$100,00)"
                                    className={styles.input}
                                    value={secondCardValuee}
                                    onChange={(e) => handleCardValueChange(e, 'second')}
                                />
                                <p className={styles.limitInfo}>
                                    Limite total disponível: <strong>R$ {initialTotal.toFixed(2)}</strong>
                                </p>
                                <p className={styles.remainingInfo}>
                                    Valor disponível restante: <strong>R$ {(remainingTotal - secondCardNumericValue).toFixed(2)}</strong>
                                </p>
                            </div>
                        )}


                    </div>
                );
            case 'pix':
                return (
                    <div className={styles.pixDetails}>
                        <h3>Pagamento via PIX</h3>
                        <p>Use o QR Code gerado para efetuar o pagamento.</p>
                        {qrCode && (
                            <div className={styles.qrCodeContainer}>
                                <h4>QR Code:</h4>
                                {qrCode && <img src={`data:image/png;base64,${qrCode}`} alt="QR Code" style={{ width: '200px', height: '200px' }} />}
                                <p>Por favor, faça o pagamento via PIX utilizando o QR Code acima.</p>
                                <p><strong>PIX Copia e Cola:</strong></p>
                                {/* Input desabilitado para o PIX code */}
                                <input
                                    type="text"
                                    value={pixCode}
                                    readOnly
                                    className={styles.input3}
                                />
                                <p>Você será notificado automaticamente assim que nosso sistema confirmar a compensação do pagamento.</p>

                                {/* Botão de copiar */}
                                <button
                                    onClick={() => {
                                        navigator.clipboard.writeText(pixCode)
                                            .then(() => {
                                                alert('Código PIX copiado para a área de transferência!');
                                            })
                                            .catch((err) => {
                                                console.error('Erro ao copiar o código PIX:', err);
                                            });
                                    }}
                                    style={{
                                        padding: '10px 15px',
                                        backgroundColor: '#e63888',
                                        color: '#fff',
                                        border: 'none',
                                        borderRadius: '4px',
                                        cursor: 'pointer',
                                    }}
                                >
                                    Copiar PIX
                                </button>
                            </div>
                        )}
                        {!isButtonClicked && (
                            <button
                                className={styles.generatePixButton}
                                onClick={handlePixPaymentSubmit}
                            >
                                {isLoading ? 'Gerando QR Code...' : 'Gerar QR Code'}
                            </button>
                        )}
                    </div>
                );
            default:
                return null;
        }
    };
    return (
        <div className={styles.checkoutContainer}>
            {loading && <p>Carregando...</p>}
            {!loading && error && <p style={{ color: 'red' }}>{error}</p>}

            {!loading && data && (
                <div className={styles.formContainer}>
                    <h2>Formas de Pagamento</h2>
                    <div className={styles.tabs}>
                        <button
                            className={paymentOption === 'oneCard' ? styles.activeTab : ''}
                            onClick={() => {
                                setPaymentOption('oneCard');
                                setPaymentMethod('credito');
                            }}
                        >
                            Pagar com 1 Cartão
                        </button>
                        <button
                            className={paymentOption === 'twoCards' ? styles.activeTab : ''}
                            onClick={() => {
                                setPaymentOption('twoCards');
                                setPaymentMethod('credito');
                            }}
                        >
                            Pagar com 2 Cartões
                        </button>
                        <button
                            className={paymentOption === 'pix' ? styles.activeTab : ''}
                            onClick={() => {
                                setPaymentOption('pix');
                                setPaymentMethod('pix');
                            }}
                        >
                            Pix
                        </button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <div className={styles.paymentContent}>{renderPaymentFields()}</div>
                        {paymentMethod !== 'pix' && (
                            <div className={styles.addressContainer}>
                                <h3>Endereço da Fatura do Cartão</h3>
                                <div className={styles.addressFields}>
                                    <input
                                        name="street"
                                        placeholder="Rua"
                                        onChange={handleAddressInputChange}
                                        className={styles.input}
                                    />
                                    <input
                                        name="number"
                                        placeholder="Número"
                                        onChange={handleAddressInputChange}
                                        className={styles.input}
                                    />
                                    <input
                                        name="complement"
                                        placeholder="Complemento"
                                        onChange={handleAddressInputChange}
                                        className={styles.input}
                                    />
                                    <input
                                        name="neighborhood"
                                        placeholder="Bairro"
                                        onChange={handleAddressInputChange}
                                        className={styles.input}
                                    />
                                    <input
                                        name="city"
                                        placeholder="Cidade"
                                        onChange={handleAddressInputChange}
                                        className={styles.input}
                                    />
                                    <input
                                        name="state"
                                        placeholder="Estado"
                                        onChange={handleAddressInputChange}
                                        className={styles.input}
                                    />
                                    <input
                                        name="zipCode"
                                        placeholder="CEP"
                                        onChange={handleAddressInputChange}
                                        className={styles.input}
                                    />
                                </div>
                            </div>
                        )}
                        <div className={styles.paymentButtonContainer}>
                            {paymentOption !== 'pix' && (
                                <>
                                    <input
                                        type="checkbox"
                                        checked={isChecked}
                                        onChange={handleCheckboxChange}
                                        className={styles.checkbox}
                                    />
                                    <label>Eu concordo com os termos e condições</label>
                                </>
                            )}
                            {/* Resumo da Transação */}
                            {renderPaymentSummary()}
                            {paymentOption !== 'pix' && (
                                <button
                                    type="submit"
                                    className={styles.paymentButton}
                                    disabled={isLoading || !isChecked} // Desabilita o botão se estiver carregando ou se não estiver marcado
                                >
                                    {isLoading ? 'Processando...' : 'Continuar com o pagamento'}
                                </button>
                            )}
                        </div>
                    </form>
                </div>
            )}
            {/* Modal para pagamento confirmado */}
            <Modal
                isOpen={!!openModal2} // Converte para booleano explicitamente
                onRequestClose={closeModal}
                className={styles2.modalContent}
                overlayClassName={styles2.modalOverlay}
            >
                <h2 style={{ textAlign: 'center' }}>Pagamento Confirmado</h2>
                <p style={{ textAlign: 'center' }}>
                    Seu pagamento foi processado com sucesso através da CarinaPaga. Você agora pode relaxar, pois uma conta a menos!
                </p>
                <button onClick={handleRedirect}>Ver Comprovante</button><br />
                <button onClick={handleDashboard}>Ir para Dashboard</button>
            </Modal>

            <Modal
                isOpen={!!openModal3} // Converte para booleano explicitamente
                onRequestClose={closeModal33}
                className={styles2.modalContent}
                overlayClassName={styles2.modalOverlay}
            >
                <h2 style={{ textAlign: 'center' }}>Pagamento Recusado</h2>
                <p style={{ textAlign: 'center' }}>
                    Houve um problema ao processar seu pagamento com o cartão. Por favor, verifique os dados do cartão e tente novamente.
                </p>
                <button onClick={handleDashboard}>Voltar para Dashboard</button>
            </Modal>
        </div>
    );
};

export default CheckoutFinish;
