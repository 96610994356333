import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Modal from 'react-modal';
import styles from './GestaoConta.module.css';
import Sidebar from './Sidebar';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

const Accounts = () => {
  const [errorMessage, setErrorMessage] = useState('');
  const [DadosReceived, setDadosReceived] = useState(false); // Novo estado para modal de pagamento recebido
  const [formData, setFormData] = useState({
    nome: '',
    sobrenome: '',
    email: '',
    telefone: '',
    dataNascimento: '',
    senha: '',
    confirmarSenha: '',
    cpfCnpj: '',
    endereco: {
      rua: '',
      cep: '',
      complemento: '',
      bairro: '',
      cidade: '',
      estado: ''
    }
  });
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [isConfirmPasswordVisible, setIsConfirmPasswordVisible] = useState(false);
  const history = useHistory();

  const handleOpenPaymentModal = () => {
    setDadosReceived(true); // Abre o segundo modal
  };

  const handleClosePaymentReceivedModal = () => {
    setDadosReceived(false);
  };

  // Funções de formatação
  const formatCPF = (value) => {
    return value
      .replace(/\D/g, '') // Remove caracteres não numéricos
      .replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
      .slice(0, 14);
  };

  const formatCNPJ = (value) => {
    return value
      .replace(/\D/g, '') // Remove caracteres não numéricos
      .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
      .slice(0, 18);
  };

  const formatPhone = (value) => {
    return value
      .replace(/\D/g, '') // Remove caracteres não numéricos
      .replace(/^(\d{2})(\d{5})(\d{4})$/, '($1) $2-$3')
      .slice(0, 15);
  };

  const formatCEP = (value) => {
    return value
      .replace(/\D/g, '') // Remove caracteres não numéricos
      .replace(/^(\d{5})(\d{3})$/, '$1-$2')
      .slice(0, 10);
  };

  // Manipulador de mudança de campos
  const handleChange = (e) => {
    const { name, value } = e.target;
    let formattedValue = value;

    if (name === 'telefone') {
      formattedValue = formatPhone(value);
    } else if (name === 'cep') {
      formattedValue = formatCEP(value);
    } else if (name === 'cpfCnpj') {
      // Se for CPF ou CNPJ
      if (value.length <= 14) {
        formattedValue = formatCPF(value);
      } else {
        formattedValue = formatCNPJ(value);
      }
    }

    if (name in formData.endereco) {
      setFormData({
        ...formData,
        endereco: { ...formData.endereco, [name]: formattedValue }
      });
    } else {
      setFormData({
        ...formData,
        [name]: formattedValue
      });
    }
  };

  // Alternar visibilidade da senha
  const togglePasswordVisibility = () => {
    setIsPasswordVisible(!isPasswordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setIsConfirmPasswordVisible(!isConfirmPasswordVisible);
  };

  // UseEffect para pegar os dados do usuário via API
  useEffect(() => {
    const storedCpfCnpj = localStorage.getItem('cpfcnpj');
    if (!storedCpfCnpj) {
      history.push('/login');
    } else {
      // Fazendo o fetch para pegar os dados do usuário
      fetch(`https://carinapaga.com.br/api/user/${storedCpfCnpj}`)
        .then(response => response.json())
        .then(data => {
          // Preenchendo o formulário com os dados retornados
          setFormData({
            nome: data.firstName,
            sobrenome: data.lastName,
            email: data.email,
            telefone: formatPhone(data.phone),
            dataNascimento: data.dob,
            senha: '',
            confirmarSenha: '',
            cpfCnpj: data.cpfcnpj,
            endereco: {
              rua: data.endereco_rua || '',
              cep: data.endereco_cep || '',
              complemento: data.endereco_complemento || '',
              bairro: data.endereco_bairro || '',
              cidade: data.endereco_cidade || '',
              estado: data.endereco_estado || ''
            }
          });
        })
        .catch(error => {
          console.error('Erro ao buscar dados:', error);
          setErrorMessage('Erro ao carregar os dados do usuário');
        });
    }
  }, [history]);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Verificar se as senhas são iguais
    if (formData.senha !== formData.confirmarSenha) {
      setErrorMessage('As senhas não coincidem.');
      return;
    }

    const storedCpfCnpj = localStorage.getItem('cpfcnpj');
    if (!storedCpfCnpj) {
      history.push('/login');
      return;
    }

    // Enviar dados para a rota de atualização
    fetch(`https://carinapaga.com.br/api/user/update/${storedCpfCnpj}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        firstName: formData.nome,
        lastName: formData.sobrenome,
        dob: formData.dataNascimento,
        phone: formData.telefone,
        email: formData.email,
        password: formData.senha,
        dateCreated: new Date().toISOString(),  // Aqui, por exemplo, você pode usar a data atual
        endereco_rua: formData.endereco.rua,
        endereco_cep: formData.endereco.cep,
        endereco_complemento: formData.endereco.complemento,
        endereco_bairro: formData.endereco.bairro,
        endereco_cidade: formData.endereco.cidade,
        endereco_estado: formData.endereco.estado
      })
    })
      .then(response => response.json())
      .then(data => {
        console.log(data);
        if (data.success) {  // Verifique se o sucesso é verdadeiro
          setDadosReceived(true); // Abre o segundo modal
          // history.push('/app');
        } else {
          setErrorMessage('Erro ao atualizar dados.');
        }
      })
      .catch(error => {
        console.error('Erro ao atualizar dados:', error);
        setErrorMessage('Erro ao atualizar dados.');
      });
  };

  return (
    <div className="form-container">
      <div className={styles.form}>
        <div className={styles.dashboardContent}>
          <Sidebar />
          <main className={styles.mainContent}>
            <div className={styles.cardContainer}>
              <div className={styles.card}>
                <h2>Gerenciamento da Conta</h2>
                <p>Aqui, você pode revisar e atualizar suas informações pessoais e cadastrais de forma rápida e fácil. Caso encontre algum dado incorreto, basta realizar as correções necessárias para manter sua conta sempre atualizada. Com isso, garantimos uma experiência tranquila e sem imprevistos, diretamente pela CarinaPaga.</p>

                <div className={styles.simulationCard}>
                  <h2>Dados Pessoais:</h2>
                  <form onSubmit={handleSubmit}>
                    <div className={styles.inputGroup}>
                      <div className={styles.inputField}>
                        <input
                          type="text"
                          name="nome"
                          placeholder="Nome"
                          className={styles.input}
                          value={formData.nome}
                          onChange={handleChange}
                          readOnly
                        />
                      </div>
                      <div className={styles.inputField}>
                        <input
                          type="text"
                          name="sobrenome"
                          placeholder="Sobrenome"
                          className={styles.input}
                          value={formData.sobrenome}
                          onChange={handleChange}
                          readOnly
                        />
                      </div>
                    </div>
                    <div className={styles.inputGroup}>
                      <div className={styles.inputField}>
                        <input
                          type="email"
                          name="email"
                          placeholder="Email"
                          className={styles.input}
                          value={formData.email}
                          onChange={handleChange}
                        />
                      </div>
                      <div className={styles.inputField}>
                        <input
                          type="text"
                          name="telefone"
                          placeholder="Telefone"
                          className={styles.input}
                          value={formData.telefone}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className={styles.inputGroup}>
                      <div className={styles.inputField}>
                        <input
                          type="text"
                          name="dataNascimento"
                          placeholder="Data de Nascimento"
                          className={styles.input}
                          value={formData.dataNascimento}
                          onChange={handleChange}
                          readOnly
                        />
                      </div>
                      <div className={styles.inputField}>
                        <div className={styles.passwordContainer}>
                          <input
                            type={isPasswordVisible ? 'text' : 'password'}
                            name="senha"
                            placeholder="Senha"
                            className={styles.input}
                            value={formData.senha}
                            onChange={handleChange}
                          />
                          <span onClick={togglePasswordVisibility} className={styles.eyeIcon}>
                            {isPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className={styles.inputGroup}>
                      <div className={styles.inputField}>
                        <div className={styles.passwordContainer}>
                          <input
                            type={isConfirmPasswordVisible ? 'text' : 'password'}
                            name="confirmarSenha"
                            placeholder="Confirmar Senha"
                            className={styles.input}
                            value={formData.confirmarSenha}
                            onChange={handleChange}
                          />
                          <span onClick={toggleConfirmPasswordVisibility} className={styles.eyeIcon}>
                            {isConfirmPasswordVisible ? <FaEyeSlash /> : <FaEye />}
                          </span>
                        </div>
                      </div>

                      <div className={styles.inputField}>
                        <input
                          type="text"
                          name="cpfCnpj"
                          placeholder="CPF ou CNPJ"
                          className={styles.input}
                          value={formData.cpfCnpj}
                          onChange={handleChange}
                          readOnly
                        />
                      </div>
                    </div>

                    <h2>Endereço:</h2>
                    <div className={styles.inputGroup}>
                      <div className={styles.inputField}>
                        <input
                          type="text"
                          name="rua"
                          placeholder="Rua"
                          className={styles.input}
                          value={formData.endereco.rua}
                          onChange={handleChange}
                        />
                      </div>
                      <div className={styles.inputField}>
                        <input
                          type="text"
                          name="cep"
                          placeholder="CEP"
                          className={styles.input}
                          value={formData.endereco.cep}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className={styles.inputGroup}>
                      <div className={styles.inputField}>
                        <input
                          type="text"
                          name="complemento"
                          placeholder="Complemento"
                          className={styles.input}
                          value={formData.endereco.complemento}
                          onChange={handleChange}
                        />
                      </div>
                      <div className={styles.inputField}>
                        <input
                          type="text"
                          name="bairro"
                          placeholder="Bairro"
                          className={styles.input}
                          value={formData.endereco.bairro}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    <div className={styles.inputGroup}>
                      <div className={styles.inputField}>
                        <input
                          type="text"
                          name="cidade"
                          placeholder="Cidade"
                          className={styles.input}
                          value={formData.endereco.cidade}
                          onChange={handleChange}
                        />
                      </div>
                      <div className={styles.inputField}>
                        <input
                          type="text"
                          name="estado"
                          placeholder="Estado"
                          className={styles.input}
                          value={formData.endereco.estado}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                    {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                    <button type="submit" className={styles.submitButton}>Atualizar</button>
                  </form>
                  <p>Caso haja qualquer alteração incorreta nos dados, por favor, entre em contato com o suporte da CarinaPaga para assistência.</p>
                  <p style={{ marginTop: '-20px' }}><a href='https://wa.me/11978641820' className={styles.registerLink}>Suporte Técnico</a></p>
                </div>
                <Modal isOpen={DadosReceived} onRequestClose={handleClosePaymentReceivedModal} className={styles.modalContent2} overlayClassName={styles.modalOverlay}>
                  <h2 style={{ textAlign: 'center' }}>Dados Atualizados com Sucesso!</h2>
                  <p style={{ textAlign: 'center' }}>
                    As informações fornecidas foram atualizadas com sucesso no sistema.
                    Agora seus dados estão atualizados e prontos para serem utilizados. Obrigado por confiar na CarinaPaga!
                  </p>
                  {/* Adicione mais informações sobre o pagamento, se necessário */}
                  <button onClick={handleClosePaymentReceivedModal}>Fechar</button>
                </Modal>
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default Accounts;
