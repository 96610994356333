import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import styles from './PagarBoletos.module.css';
import Sidebar from './Sidebar';
import FormatBarcode from './FormatBarcode'; // Importando o componente FormatBarcode
// Importe a imagem
import pcicomplianceImage from '../assets/pcicompliance.png';

const PagamentoBoletos = () => {
  const [digitType, setDigitType] = useState('47'); // Tipo de dígito selecionado
  const [barcodeParts, setBarcodeParts] = useState([]); // Estado para armazenar os valores dos inputs
  const [errorMessage, setErrorMessage] = useState('');
  const [formattedBarcode, setFormattedBarcode] = useState(''); // Estado para o código formatado
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();


  useEffect(() => {
    const storedCpfCnpj = localStorage.getItem('cpfcnpj');
    if (!storedCpfCnpj) {
      history.push('/login');
    }
  }, [history]);

  const handleSelectChange = (e) => {
    setDigitType(e.target.value);
    setBarcodeParts([]); // Limpa os valores ao mudar o tipo de boleto
  };

  const handleInputChange = (e, index) => {
    const value = e.target.value.replace(/\D/g, ''); // Apenas números
    setBarcodeParts((prev) => {
      const updated = [...prev];
      updated[index] = value; // Atualiza apenas a posição correta
      console.log('Valores atualizados:', updated);
      return updated;
    });
  };

  const generateInputs = () => {
    const totalDigits = parseInt(digitType, 10);
    const inputs = [];

    if (totalDigits === 47) {
      const steps = [5, 5, 5, 6, 5, 6, 1, 14]; // Divisão para 47 dígitos
      steps.forEach((step, i) => {
        inputs.push(
          <input
            key={i}
            type="text"
            maxLength={step}
            value={barcodeParts[i] || ''} // Corrige o uso do índice correto
            onChange={(e) => handleInputChange(e, i)} // Usa `i` diretamente
            className={styles.input}
            placeholder={`Digite ${step} dígitos`}
          />
        );
      });
    } else if (totalDigits === 48) {
      const step = 12; // Divisão para 48 dígitos
      for (let i = 0; i < 4; i++) {
        inputs.push(
          <input
            key={i}
            type="text"
            maxLength={step}
            value={barcodeParts[i] || ''}
            onChange={(e) => handleInputChange(e, i)}
            className={styles.input}
            placeholder={`Digite ${step} dígitos`}
          />
        );
      }
    }

    return inputs;
  };

  const handleSimular = () => {
    if (barcodeParts.length === 0 || barcodeParts.some((part) => part === undefined || part === '')) {
      setErrorMessage('Por favor, preencha todos os campos.');
      return;
    }

    const fullBarcode = barcodeParts.join('');
    // console.log('Todo o código de Barras juntos:', fullBarcode);

    if (fullBarcode.length !== parseInt(digitType, 10)) {
      setErrorMessage(`O código deve ter exatamente ${digitType} dígitos.`);
      return;
    }

    setErrorMessage('');
    setIsLoading(true);

    setTimeout(() => {
      setIsLoading(false);
      setFormattedBarcode(fullBarcode);
    }, 5000);
  };

  const handlePaste = async () => {
    try {
      const clipboardText = await navigator.clipboard.readText();
      const sanitizedText = clipboardText.replace(/\s+/g, '').replace(/\D/g, ''); // Remove espaços e caracteres não numéricos

      const totalDigits = parseInt(digitType, 10);
      let steps = [];

      if (totalDigits === 47) {
        steps = [5, 5, 5, 6, 5, 6, 1, 14];
      } else if (totalDigits === 48) {
        steps = [12, 12, 12, 12];
      }

      const parts = [];
      let startIndex = 0;

      steps.forEach((step) => {
        parts.push(sanitizedText.slice(startIndex, startIndex + step));
        startIndex += step;
      });

      if (parts.join('').length !== totalDigits) {
        setErrorMessage(`O código de barras copiado não corresponde a ${digitType} dígitos, tente mudar o tipo de conta.`);
        return;
      }

      setBarcodeParts(parts);
      setErrorMessage('');
    } catch (error) {
      setErrorMessage('Erro ao acessar o clipboard. Verifique as permissões.');
    }
  };

  return (
    <div className="form-container">
      <div className={styles.form}>
        <div className={styles.dashboardContent}>
          <Sidebar />
          <main className={styles.mainContent}>
            <div className={styles.cardContainer}>
              <div className={styles.card}>
                {isLoading ? (
                  <div className={styles.loadingContainer}>
                    <div className={styles.spinner}></div>
                    <p>
                      Estamos processando o seu código de barras. Por favor, aguarde enquanto finalizamos a verificação no nosso sistema.
                    </p>
                  </div>
                ) : formattedBarcode ? ( // Exibe o código formatado, substituindo o card de simulação
                  <div className={styles.resultContainer}>
                    <h2>Resultado da Consulta:</h2>
                    <FormatBarcode code={formattedBarcode} /> {/* Passando o código formatado para o componente FormatBarcode */}
                  </div>
                ) : (
                  <>
                    <h2>Simule os seus pagamentos com a CarinaPaga!</h2>
                    <p>
                      Com a CarinaPaga, você pode simular diferentes opções de pagamento para suas faturas e despesas. Nossa plataforma oferece ferramentas intuitivas para que você possa visualizar e comparar alternativas de parcelamento e quitação, proporcionando uma visão clara e prática do impacto financeiro de suas escolhas. Experimente nossa simulação para encontrar a solução que melhor se adapta ao seu orçamento e aproveite uma gestão de pagamentos mais eficiente e personalizada.
                    </p>
                    <div className={styles.imageContainer}>
                      <img src={pcicomplianceImage} alt="PCI Compliance" className={styles.pciImage} />
                    </div>
                    <div className={styles.simulationCard}>
                      <label htmlFor="digitType">Selecione o tipo de boleto:</label>
                      <select
                        id="digitType"
                        value={digitType}
                        onChange={handleSelectChange}
                        className={styles.select}
                      >
                        <option value="47">Outros Boletos (47 dígitos)</option>
                        <option value="48">Água & Energia (48 dígitos)</option>
                      </select>
                      <div className={styles.inputcontainer}>
                        {generateInputs()}
                      </div>
                      <button onClick={handlePaste} className={styles.button}>
                        Colar Código de Barras
                      </button>
                      <button onClick={handleSimular} className={styles.button}>
                        Simular
                      </button>
                      {errorMessage && <p className={styles.error}>{errorMessage}</p>}
                      <p style={{ marginTop: '-5px' }}>Consulte as taxas de serviço da CarinaPaga:<br></br><a href='https://carinapaga.com.br/termos.pdf' className={styles.registerLink}>Taxas & Encargos</a></p>
                    </div>
                  </>
                )}
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default PagamentoBoletos;
