import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import Button from '@mui/material/Button';
import HomeIcon from '@mui/icons-material/Home';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import ChecklistRtlIcon from '@mui/icons-material/ChecklistRtl';
import SendToMobileIcon from '@mui/icons-material/SendToMobile';
import SettingsIcon from '@mui/icons-material/Settings';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import styles from './Sidebar.module.css';

const Sidebar = () => {
  const history = useHistory();
  const location = useLocation();
  const [submenuVisible, setSubmenuVisible] = useState({
    users: false,
    payments: false,
    reports: false,
    settings: false
  });
  const [menuVisible, setMenuVisible] = useState(true); // Controla a visibilidade do menu

  const toggleSubmenu = (menu) => {
    setSubmenuVisible((prevState) => {
      const newState = {
        users: false,
        payments: false,
        reports: false,
        settings: false,
      };
      newState[menu] = !prevState[menu];
      return newState;
    });
  };

  const toggleMenu = () => {
    setMenuVisible((prevState) => !prevState); // Alterna a visibilidade do menu
  };

  const WarningCaution = () => {
    toast.warn('Este módulo está em manutenção, lamentamos o ocorrido!');
  };

  const handleLogout = async () => {
    try {
      await fetch('https://carinapaga.com.br/api/logout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      localStorage.removeItem('cpfcnpj');
      history.push('/login');
    } catch (error) {
      console.error('Erro ao fazer logout:', error);
    }
  };

  const HomeApp = () => {
    history.push('/app');
  };

  const SimulatePayment = () => {
    history.push('/simulatepayment');
  };

  const isSimulatePaymentPage = location.pathname === '/simulatepayment';

  return (
    <aside className={`${styles.sidebar} ${menuVisible ? styles.open : styles.closed}`}>
      <h1>CarinaPaga</h1> <button
        className={styles.hamburgerMenuButton}
        onClick={toggleMenu}
        aria-label="Toggle Sidebar"
      >
        ☰
      </button>
      {menuVisible && (
        <div className={styles.sidebarContent}>

          <ul>
            <li>
              <Button
                style={{
                  background: 'rgb(255, 255, 255)',
                  color: '#000',
                  border: '1px solid #e63888',
                  borderRadius: '5px',
                  fontSize: '0.8rem',
                  padding: '0.5rem 1rem',
                  height: 'auto',
                  letterSpacing: '1px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
                startIcon={<HomeIcon />}
                onClick={HomeApp}
              >
                Home
              </Button>
            </li>
            <li>
              <Button
                style={{
                  background: 'rgb(255, 255, 255)',
                  color: '#000',
                  border: '1px solid #e63888',
                  borderRadius: '5px',
                  fontSize: '0.8rem',
                  padding: '0.5rem 1rem',
                  height: 'auto',
                  letterSpacing: '1px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
                startIcon={<ShoppingCartIcon />}
                onClick={() => toggleSubmenu('payments')}
              >
                Pagamentos
                {submenuVisible.payments ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Button>
              {submenuVisible.payments && (
                <ul className={styles.submenu}>
                  <li>
                    <Button onClick={() => window.open("https://debitosveiculares.celcoin.com.br/webview/carinabank", "_blank")}>Pagamentos de Débitos Veiculares</Button>
                  </li>
                  <li>
                    <Button onClick={() => history.push('/pagar-boletos')}>Pagamentos de Boletos</Button>
                  </li>
                  <li>
                    <Button onClick={() => history.push('/pagar-pix')}>Pagamentos de QR Code PIX</Button>
                  </li>
                  <li>
                    <Button
                      className={isSimulatePaymentPage ? styles.activeButton : styles.inactiveButton} onClick={SimulatePayment}
                    >
                      Simular Pagamentos
                    </Button>
                  </li>
                </ul>
              )}
            </li>
            <li className={styles.hideOnMobile}>
              <Button
                style={{
                  background: 'rgb(255, 255, 255)',
                  color: '#000',
                  border: '1px solid #e63888',
                  borderRadius: '5px',
                  fontSize: '0.8rem',
                  padding: '0.5rem 1rem',
                  height: 'auto',
                  letterSpacing: '1px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
                startIcon={<ChecklistRtlIcon />}
                onClick={() => toggleSubmenu('reports')}
              >
                Relatórios
                {submenuVisible.reports ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Button>
              {submenuVisible.reports && (
                <ul className={styles.submenu}>
                  <li>
                    <Button onClick={() => history.push('/list-transations')}>
                      Transações
                    </Button>
                  </li>
                </ul>
              )}
            </li>

            {/* <li>
              <Button
                style={{
                  background: 'rgb(255, 255, 255)',
                  color: '#000',
                  border: '1px solid #e63888',
                  borderRadius: '5px',
                  fontSize: '0.8rem',
                  padding: '0.5rem 1rem',
                  height: 'auto',
                  letterSpacing: '1px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                }}
                startIcon={<SendToMobileIcon />}
                onClick={WarningCaution}
              >
                Recarga de Celular
              </Button>
            </li> */}
            <li>
              <Button
                style={{
                  background: 'rgb(255, 255, 255)',
                  color: '#000',
                  border: '1px solid #e63888',
                  borderRadius: '5px',
                  fontSize: '0.8rem',
                  padding: '0.5rem 1rem',
                  height: 'auto',
                  letterSpacing: '1px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
                startIcon={<SettingsIcon />}
                onClick={() => toggleSubmenu('settings')}
              >
                Configurações
                {submenuVisible.settings ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </Button>
              {submenuVisible.settings && (
                <ul className={styles.submenu}>
                  <li>
                    <Button onClick={() => history.push('/account')}>Gestão da Conta</Button>
                  </li>
                  {/* <li>
                    <Button onClick={WarningCaution}>Gestão de Crédito</Button>
                  </li> */}
                </ul>
              )}
            </li>
            <li>
              <Button
                style={{
                  background: 'rgb(255, 255, 255)',
                  color: '#000',
                  border: '1px solid #e63888',
                  borderRadius: '5px',
                  fontSize: '0.8rem',
                  padding: '0.5rem 1rem',
                  height: 'auto',
                  letterSpacing: '1px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'flex-start',
                  width: '100%',
                }}
                startIcon={<ExitToAppIcon />}
                onClick={handleLogout}
              >
                Sair
              </Button>
            </li>
          </ul>
        </div>
      )}
      <ToastContainer />
    </aside>
  );
};

export default Sidebar;
