import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ModalComponent from './ModalSimulatePayment';
import styles from './SimulatePayment.module.css';
import Sidebar from './Sidebar';

// Importe a imagem
import pcicomplianceImage from '../assets/pcicompliance.png';

const FormFaturas = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [valor, setValor] = useState('');
  const [billType, setBillType] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const history = useHistory();

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setErrorMessage('');
  };

  const isPixCode = (code) => {
    return code.length === 32;
  };

  const handleSimular = () => {
    const unformattedValor = valor.replace(/\D/g, ''); // Remove a formatação antes de enviar

    if (isPixCode(unformattedValor)) {
      setErrorMessage('Código PIX detectado! Por favor, insira um código de barras de boleto.');
      return;
    }

    setBillType(unformattedValor);
    openModal(unformattedValor);
  };

  //  const handlePaste = async () => {
  //   try {
  //     const clipboardText = await navigator.clipboard.readText();
  //     const sanitizedText = clipboardText.replace(/\s+/g, '').replace(/\D/g, ''); // Remove espaços e caracteres não numéricos

  //     const totalDigits = parseInt(digitType, 10);
  //     let steps = [];

  //     if (totalDigits === 47) {
  //       steps = [5, 5, 5, 6, 5, 6, 1, 14];
  //     } else if (totalDigits === 48) {
  //       steps = [12, 12, 12, 12];
  //     }

  //     const parts = [];
  //     let startIndex = 0;

  //     steps.forEach((step) => {
  //       parts.push(sanitizedText.slice(startIndex, startIndex + step));
  //       startIndex += step;
  //     });

  //     if (parts.join('').length !== totalDigits) {
  //       setErrorMessage(`O código de barras copiado não corresponde a ${digitType} dígitos, tente mudar o tipo de conta.`);
  //       return;
  //     }

  //     setBarcodeParts(parts);
  //     setErrorMessage('');
  //   } catch (error) {
  //     setErrorMessage('Erro ao acessar o clipboard. Verifique as permissões.');
  //   }
  // };


  const formatBarcode = (code) => {
    const onlyNumbers = code.replace(/\D/g, '');
    const parts = [
      onlyNumbers.slice(0, 5),  
      onlyNumbers.slice(5, 10), 
      onlyNumbers.slice(10, 15),
      onlyNumbers.slice(15, 21), 
      onlyNumbers.slice(21, 26),
      onlyNumbers.slice(26, 32),
      onlyNumbers.slice(32, 33), 
      onlyNumbers.slice(33)      
    ].filter(part => part.length > 0);
  
    return parts.join(' ');
  };
  
  const handleChange = (e) => {
    setValor(formatBarcode(e.target.value));
  };

  useEffect(() => {
    const storedCpfCnpj = localStorage.getItem('cpfcnpj');

    if (!storedCpfCnpj) {
      history.push('/login');
    } else {
      history.push('/simulatepayment');
    }
  }, [history]);

  return (
    <div className="form-container">
      <div className={styles.form}>
        <div className={styles.dashboardContent}>
          <Sidebar />
          <main className={styles.mainContent}>
            <div className={styles.cardContainer}>
              <div className={styles.card}>
                <h2>Simule os seus pagamentos com a CarinaPaga!<br /></h2>
                <p>
                  Com a CarinaPaga, você pode simular diferentes opções de pagamento para suas faturas e despesas. Nossa plataforma oferece ferramentas intuitivas para que você possa visualizar e comparar alternativas de parcelamento e quitação, proporcionando uma visão clara e prática do impacto financeiro de suas escolhas. Experimente nossa simulação para encontrar a solução que melhor se adapta ao seu orçamento e aproveite uma gestão de pagamentos mais eficiente e personalizada.
                </p>
                <br />

                {/* Adicione a imagem aqui */}
                <div className={styles.imageContainer}>
                  <img src={pcicomplianceImage} alt="PCI Compliance" className={styles.pciImage} />
                </div>

                <div className={styles.simulationCard}>
                  <h2>Insira aqui o código de barras do seu boleto/faturas</h2>
                    <input
                      type="text"
                      id="barcode"
                      placeholder="Código de Barras"
                      className={styles.input}
                      value={valor}
                      onChange={handleChange}
                    />
                  <button onClick={handleSimular}>Simular</button>
                  {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
                  <p>Seu boleto/fatura será pesquisado em nosso sistema em alguns instantes após o clique no botão.</p>
                  <p style={{ marginTop: '-20px' }}>Consulte as taxas de serviço da CarinaPaga:<br></br><a href='https://www.carinapaga.com' className={styles.registerLink}>Taxas & Encargos</a></p>
                </div>
                <ModalComponent 
                  isOpen={modalIsOpen} 
                  closeModal={closeModal} 
                  billType={billType}
                  billType2={valor}
                />
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default FormFaturas;
