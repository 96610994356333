import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import ModalComponent from './ModalSimulatePayment2';
import styles from './PaymentPIX.module.css';
import Sidebar from './Sidebar';

const FormFaturas23 = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [valor, setValor] = useState('');
  const [billType, setBillType] = useState(''); // Estado para armazenar o tipo de boleto/fatura
  const [errorMessage, setErrorMessage] = useState(''); // Estado para armazenar a mensagem de erro
  const history = useHistory();

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setErrorMessage(''); // Limpa a mensagem de erro ao fechar o modal
  };

  // const isPixCode = (code) => {
  //   // Verifica se o código fornecido corresponde ao formato de um código PIX
  //   // Aqui você pode ajustar a lógica para detectar um código PIX
  //   // Exemplo simples: um código PIX geralmente tem 32 caracteres
  //   return code.length === 32; // Altere esta condição conforme necessário
  // };

  const handleSimular = () => {
    // Verifica se o valor é um código PIX
    // if (isPixCode(valor)) {
    //   setErrorMessage('Código PIX detectado! Por favor, insira um código de barras de boleto.');
    //   return; // Interrompe o processamento se um código PIX for detectado
    // }

    // Define o tipo de boleto/fatura ou qualquer outro dado relevante
    setBillType(valor);
    openModal(valor);
  };

  useEffect(() => {
    const storedCpfCnpj = localStorage.getItem('cpfcnpj');

    if (!storedCpfCnpj) {
      // Se não há CPF/CNPJ no localStorage, redireciona para a página de login
      history.push('/login');
    } else {
      // Se há, redireciona para a página de simulação de pagamento
      history.push('/pagar-pix');
    }
  }, [history]);

  return (
    <div className="form-container">
      <div className={styles.form}>
        <div className={styles.dashboardContent}>
          <Sidebar />
          <main className={styles.mainContent}>
            <div className={styles.cardContainer}>
              <div className={styles.card}>
                <h2>Quite suas contas de forma rápida e fácil com a CarinaPaga!</h2>
                <p>Com a CarinaPaga, você pode pagar suas faturas e despesas de maneira rápida e prática utilizando o PIX. O pagamento via PIX é instantâneo e seguro, garantindo que suas contas sejam quitadas imediatamente, sem complicações. Aproveite a agilidade e a conveniência do PIX para uma gestão de pagamentos mais eficiente e livre de burocracia, tudo através da CarinaPaga.</p>
                <br />

                <div className={styles.simulationCard}>
                <h2>Insira aqui o código QR do seu boleto/faturas</h2>
                  <div className={styles.input} style={{ marginLeft: '12em' }}>
                    <input
                      type="text"
                      id="barcode"
                      placeholder="Código QR"
                      className={styles.input}
                      value={valor}
                      onChange={(e) => setValor(e.target.value)}
                    />
                  </div>
                  <button onClick={handleSimular}>Avançar</button>
                  {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>} {/* Exibe mensagem de erro */}
                  <p>Seu boleto/fatura será pesquisado em nosso sistema em alguns instantes após o clique no botão.</p>
                  <p style={{ marginTop: '-20px' }}>Consulte as taxas de serviço da CarinaPaga:<br></br><a href='https://carinapaga.com.br/termos.pdf' className={styles.registerLink}>Taxas & Encargos</a></p>
                </div>
                <ModalComponent 
                  isOpen={modalIsOpen} 
                  closeModal={closeModal} 
                  billType={billType}
                  billType2={valor}
                />
              </div>
            </div>
          </main>
        </div>
      </div>
    </div>
  );
};

export default FormFaturas23;
