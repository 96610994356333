import { Accordion } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import './FAQAccordion.css'; // Importe o arquivo CSS

function FAQAccordion() {
  return (
    <Accordion>
      <Accordion.Item eventKey="0" className="accordion-item-custom">
        <Accordion.Header className="accordion-header-custom" style={{ fontWeight: 'bolder' }}>
          O que pode ser parcelado?
        </Accordion.Header>
        <Accordion.Body className="accordion-body-custom">
          Na CarinaPaga, oferecemos uma plataforma completa para facilitar a gestão de pagamentos de faturas. Aqui, você pode acompanhar, parcelar e quitar seus débitos com total segurança e praticidade. Além disso, você pode gerenciar o pagamento de faturas de boletos, água, energia, débitos veiculares e outros serviços essenciais, tudo em um só lugar, garantindo mais conveniência e controle sobre suas finanças.
        </Accordion.Body>

      </Accordion.Item>
      <Accordion.Item eventKey="1" className="accordion-item-custom">
        <Accordion.Header className="accordion-header-custom">
          Quais as formas de pagamento?
        </Accordion.Header>
        <Accordion.Body className="accordion-body-custom">
          Você pode pagar com cartão de crédito em até 12x e com PIX, além de poder utilizar até 2 cartões.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="2" className="accordion-item-custom">
        <Accordion.Header className="accordion-header-custom">
          Posso pagar com cartões de amigos ou parentes?
        </Accordion.Header>
        <Accordion.Body className="accordion-body-custom">
          Sim. Você pode pagar com o cartão de outra pessoa, entretanto, nesses casos é realizada previamente uma análise de segurança e titularidade do cartão.
        </Accordion.Body>
      </Accordion.Item>
      <Accordion.Item eventKey="3" className="accordion-item-custom">
        <Accordion.Header className="accordion-header-custom">
          Quanto tempo demora a regularização do débito, após sua quitação?
        </Accordion.Header>
        <Accordion.Body className="accordion-body-custom">
          Assim que o pedido é concluído, ele passa pela análise de segurança que pode durar até 48 horas úteis independente do estado. Quando o pedido é aprovado, os débitos seguirão para liquidação junto à rede bancária. Após a liquidação bancária, os órgãos solicitam um prazo de 48h úteis e esse prazo pode se estender a até 96 horas úteis dependendo do estado e tipo de débito, para que os débitos sejam baixados no sistema. É importante lembrar que, alguns débitos podem baixar mais rápido e outros podem demorar um pouco mais, como no caso de débitos municipais e dívida ativa.        </Accordion.Body>
      </Accordion.Item>
    </Accordion>
  );
}

export default FAQAccordion;
